/** 等级 */
export const GRADE_TYPE = [
  {
    value: 'A',
    label: 'A'
  },
  {
    value: 'B',
    label: 'B'
  },
  {
    value: 'C',
    label: 'C'
  },
  {
    value: 'D',
    label: 'D'
  }]
export const BUYER_TYPE = [
  {
    id: '',
    name: '全部'
  },
  {
    id: '2',
    name: '招商'
  },
  {
    id: '3',
    name: '买手'
  },
 ]
export const QUERY_TAG_LIST = [
  {
    value: 'logistic_business_id',
    label: '集配中心',
    type:3,
    listname:'businessList'
  },
  {
    value: 'first_cid',
    label: '一级品类',
    type:3,
    listname:'CategoriesList'
  },
  {
    value: 'cid',
    label: '二级品类',
    type:3,
      listname:'subList'
  },
  {
    value: 'category_tag_id',
    label: '品种',
    type:3,
    listname:'catrglist'
  },
  {
    value: 'grade',
    label: '商品等级',
    type:1
  },
  
  {
    value: 'buyer_type',
    label: '来源',
    type:3,
    listname:''
  },
  {
    value: 'supplier_name',
    label: '供应商',
    type:1
  },
  {
    value: 'buyer_name',
    label: '业务',
    type:1
  },
  {
    value: ['price_min','price_max'],
    label: '开市价',
    type:2
  },
  {
    value: ['weight_min','weight_max'],
    label: '净重',
    type:2
  },
  {
    value: ['gross_weight_min','gross_weight_max'],
    label: '毛重',
    type:2
  },
  {
    value: ['aftersale_apply_rate_min','aftersale_apply_rate_max'],
    label: '发起率',
    type:2
  },
  {
    value: ['aftersale_rate_min','aftersale_rate_max'],
    label: '售后率',
    type:2
  },
  {
    value: ['delivery_time_min','delivery_time_max'],
    label: '产地发货时间',
    type:2,time:'delivery_time'
  },
  {
    value: ['arrival_time_min','arrival_time_max'],
    label: '新货到市场时间',
    type:2,time:'arrival_time'
  },
  {
    value: ['shelf_life_min','shelf_life_max'],
    label: '剩余货架期/保质期',
    type:2,
    time:'shelf_life'
  },
  {
    value: ['stock_min','stock_max'],
    label: '库存',
    type:2
  },
  {
    value: ['audit_time_min','audit_time_max'],
    label: '提审时间',
    type:2,
    time:'audit_time'
  },
  {
    value: 'origin_name',
    label: '产地',
    type:1
  },
 ]


