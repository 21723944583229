import { BASE } from "../index";
import axios from "../http";

const BASE_URL = BASE.PRO1;

/**
 * 总控-供应商管理-营业执照认证
 * @param {*} params
 * @returns
 */
export function supplierlicenseverify(params = {}) {
  return axios.post(`${BASE_URL}/supplier/license/verify`, params);
}
/**
 * 总控-供应商管理-身份认证状态修改
 * @param {*} params
 * @returns
 */
export function getUpdateIdStatus(params = {}) {
  return axios.post(`${BASE_URL}/supplier/updateIdStatus`, params);
}

/**
 * 总控-商品跟踪-获取二级品类
 * @param {*} params
 */
export const getSubList = (params = {}) => {
  return axios.get(`${BASE_URL}/categories/sub/list`, { params });
};

/**
 * 总控-非平台介入-强制介入
 * @param {*} params
 */
export const postForceIntervene = (params = {}) => {
  return axios.post(`${BASE_URL}/aftersale/force/intervene`, params);
};

/**
 * 总控-供应商管理-营业执照绑定记录
 * @param {*} params
 */
export const getSupplierLicense = (params = {}) => {
  return axios.get(`${BASE_URL}/supplier/license`, { params });
};

/**
 * 总控-供应商管理-营业执照绑定取消
 * @param {*} params
 */
export const getSupplierLicenseDel = (params = {}) => {
  return axios.post(`${BASE_URL}/supplier/license/del`, params);
};

/**
 * 总控-供应商管理-营业执照是否可显示
 * @param {*} params
 */
export const getSupplierLicenseEnabled = (params = {}) => {
  return axios.get(`${BASE_URL}/supplier/license/enabled`, { params });
};

/**
 * 售后率
 * @param {*} params
 */
export const postAfterSaleStatistics = (params = {}) => {
  return axios.post(`${BASE_URL}/aftersale/statistics`, params);
};

/**
 * 【供应商认证】会员卡信息认证
 * @param {*} params
 */
export const postSupplierMember_cardAuth = (params = {}) => {
  return axios.post(`${BASE_URL}/supplier/member_card/auth`, params);
};

/**
 * 【供应商认证-清除信息】
 * @param {*} params
 */
export const postSupplierMember_cardDel = (params = {}) => {
  return axios.post(`${BASE_URL}/supplier/member_card/del`, params);
};
