<template>
    <el-dialog
      title="下架原因"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onHandleClose"
      width="60%"
      class="dialog"
      center
    >
      <div class="contents" >
        <el-table
          :data="tableData"
          height="380"
          :border="true"
          :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        >
          <el-table-column type="index" width="100px" align="center" label="序号">
          </el-table-column>
          <el-table-column
            prop="reason"
            align="center"
            label="下架原因"
          >
          </el-table-column>
          <el-table-column
            prop="op_name"
            align="center"
            label="操作人"
          >
          </el-table-column>
          <el-table-column
            prop="time"
            align="center"
            label="操作时间"
          >
          </el-table-column>
        
        </el-table>
  
     
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible=false">关闭</el-button>
      </span>
    </el-dialog>
  </template>
  
  <script>

  
  export default {
    name: "table-details",
    props: {},
    data() {
      return {
        dialogVisible: false,
        tableData:[]
      };
    },
    methods: {
      /**
       * 初始化
       */
      onInitData(data) {
      this.$api.examine.producttakeoffreason({sku_code:data.sku_code}).then(res=>{
this.tableData=res.data
      })
        this.dialogVisible = true;
      },

  
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .dialog {
    .contents {
      padding-top: 30px;
      padding-bottom: 20px;
    }
    .tooltip {
      margin-bottom: 30px;
    }
    // /deep/ .el-form {
    // }
    .el-input {
      width: 300px;
    }
  }
  </style>
  