var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Selected-products bj" },
    [
      _c("div", { staticClass: "title" }, [
        _vm.hideshow
          ? _c("div", { staticClass: "title-query" }, [
              _c("div", { staticClass: "title-query-input" }, [
                _c("div", { staticClass: "font" }, [_vm._v("集配中心：")]),
                _c(
                  "div",
                  { staticClass: "input-item" },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          clearable: "",
                          filterable: "",
                          placeholder: "选择集配中心",
                        },
                        model: {
                          value: _vm.from.logistic_business_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.from, "logistic_business_id", $$v)
                          },
                          expression: "from.logistic_business_id",
                        },
                      },
                      _vm._l(_vm.businessList, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "title-query-input" }, [
                _c("div", { staticClass: "font" }, [_vm._v("一级品类：")]),
                _c(
                  "div",
                  { staticClass: "input-item" },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          clearable: "",
                          filterable: "",
                          placeholder: "选择一级品类",
                        },
                        model: {
                          value: _vm.from.first_cid,
                          callback: function ($$v) {
                            _vm.$set(_vm.from, "first_cid", $$v)
                          },
                          expression: "from.first_cid",
                        },
                      },
                      _vm._l(_vm.CategoriesList, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "title-query-input" }, [
                _c("div", { staticClass: "font" }, [_vm._v("二级品类：")]),
                _c(
                  "div",
                  { staticClass: "input-item" },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          filterable: "",
                          clearable: "",
                          placeholder: "选择二级品类",
                        },
                        on: { change: _vm.subListcate },
                        model: {
                          value: _vm.from.cid,
                          callback: function ($$v) {
                            _vm.$set(_vm.from, "cid", $$v)
                          },
                          expression: "from.cid",
                        },
                      },
                      _vm._l(_vm.subList, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "title-query-input" }, [
                _c("div", { staticClass: "font" }, [_vm._v("品种：")]),
                _c(
                  "div",
                  { staticClass: "input-item" },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          clearable: "",
                          filterable: "",
                          placeholder: "选择品种",
                        },
                        model: {
                          value: _vm.from.category_tag_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.from, "category_tag_id", $$v)
                          },
                          expression: "from.category_tag_id",
                        },
                      },
                      _vm._l(_vm.catrglist, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "title-query-input" }, [
                _c("div", { staticClass: "font" }, [_vm._v("商品名称：")]),
                _c(
                  "div",
                  { staticClass: "input-item" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入商品名称", clearable: "" },
                      model: {
                        value: _vm.from.goodsName,
                        callback: function ($$v) {
                          _vm.$set(_vm.from, "goodsName", $$v)
                        },
                        expression: "from.goodsName",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "title-query-input" }, [
                _c("div", { staticClass: "font" }, [_vm._v("规格：")]),
                _c(
                  "div",
                  { staticClass: "input-item" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入规格", clearable: "" },
                      model: {
                        value: _vm.from.specName,
                        callback: function ($$v) {
                          _vm.$set(_vm.from, "specName", $$v)
                        },
                        expression: "from.specName",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "title-query-input" }, [
                _c("div", { staticClass: "font" }, [_vm._v("商品等级：")]),
                _c(
                  "div",
                  { staticClass: "input-item" },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { clearable: "", placeholder: "选择商品等级" },
                        model: {
                          value: _vm.from.grade,
                          callback: function ($$v) {
                            _vm.$set(_vm.from, "grade", $$v)
                          },
                          expression: "from.grade",
                        },
                      },
                      _vm._l(_vm.GRADE_TYPE, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "title-query-input" }, [
                _c("div", { staticClass: "font" }, [_vm._v("来源：")]),
                _c(
                  "div",
                  { staticClass: "input-item" },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { clearable: "", placeholder: "选择来源" },
                        model: {
                          value: _vm.from.buyer_type,
                          callback: function ($$v) {
                            _vm.$set(_vm.from, "buyer_type", $$v)
                          },
                          expression: "from.buyer_type",
                        },
                      },
                      _vm._l(_vm.BUYER_TYPE, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "title-query-input" }, [
                _c("div", { staticClass: "font" }, [_vm._v("供应商：")]),
                _c(
                  "div",
                  { staticClass: "input-item" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入供应商", clearable: "" },
                      model: {
                        value: _vm.from.supplier_name,
                        callback: function ($$v) {
                          _vm.$set(_vm.from, "supplier_name", $$v)
                        },
                        expression: "from.supplier_name",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "title-query-input" }, [
                _c("div", { staticClass: "font" }, [_vm._v("业务：")]),
                _c(
                  "div",
                  { staticClass: "input-item" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入业务", clearable: "" },
                      model: {
                        value: _vm.from.buyer_name,
                        callback: function ($$v) {
                          _vm.$set(_vm.from, "buyer_name", $$v)
                        },
                        expression: "from.buyer_name",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "title-query-input" }, [
                _c("div", { staticClass: "font" }, [_vm._v("开市价：")]),
                _c(
                  "div",
                  { staticClass: "input-item-tow" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "开市价始", clearable: "" },
                      on: {
                        blur: function ($event) {
                          return _vm.handleInputMoney(
                            _vm.from.price_min,
                            "price_min"
                          )
                        },
                      },
                      model: {
                        value: _vm.from.price_min,
                        callback: function ($$v) {
                          _vm.$set(_vm.from, "price_min", $$v)
                        },
                        expression: "from.price_min",
                      },
                    }),
                    _c("span", { staticClass: "span" }, [_vm._v("至")]),
                    _c("el-input", {
                      attrs: { placeholder: "开市价止", clearable: "" },
                      on: {
                        blur: function ($event) {
                          return _vm.handleInputMoney(
                            _vm.from.price_max,
                            "price_max"
                          )
                        },
                      },
                      model: {
                        value: _vm.from.price_max,
                        callback: function ($$v) {
                          _vm.$set(_vm.from, "price_max", $$v)
                        },
                        expression: "from.price_max",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "title-query-input" }, [
                _c("div", { staticClass: "font" }, [_vm._v("净重：")]),
                _c(
                  "div",
                  { staticClass: "input-item-tow" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "净重始", clearable: "" },
                      on: {
                        blur: function ($event) {
                          return _vm.handleInputMoney(
                            _vm.from.weight_min,
                            "weight_min"
                          )
                        },
                      },
                      model: {
                        value: _vm.from.weight_min,
                        callback: function ($$v) {
                          _vm.$set(_vm.from, "weight_min", $$v)
                        },
                        expression: "from.weight_min",
                      },
                    }),
                    _c("span", { staticClass: "span" }, [_vm._v("至")]),
                    _c("el-input", {
                      attrs: { placeholder: "净重止", clearable: "" },
                      on: {
                        blur: function ($event) {
                          return _vm.handleInputMoney(
                            _vm.from.weight_max,
                            "weight_max"
                          )
                        },
                      },
                      model: {
                        value: _vm.from.weight_max,
                        callback: function ($$v) {
                          _vm.$set(_vm.from, "weight_max", $$v)
                        },
                        expression: "from.weight_max",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "title-query-input" }, [
                _c("div", { staticClass: "font" }, [_vm._v("毛重：")]),
                _c(
                  "div",
                  { staticClass: "input-item-tow" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "毛重始", clearable: "" },
                      on: {
                        blur: function ($event) {
                          return _vm.handleInputMoney(
                            _vm.from.gross_weight_min,
                            "gross_weight_min"
                          )
                        },
                      },
                      model: {
                        value: _vm.from.gross_weight_min,
                        callback: function ($$v) {
                          _vm.$set(_vm.from, "gross_weight_min", $$v)
                        },
                        expression: "from.gross_weight_min",
                      },
                    }),
                    _c("span", { staticClass: "span" }, [_vm._v("至")]),
                    _c("el-input", {
                      attrs: { placeholder: "毛重止", clearable: "" },
                      on: {
                        blur: function ($event) {
                          return _vm.handleInputMoney(
                            _vm.from.gross_weight_max,
                            "gross_weight_max"
                          )
                        },
                      },
                      model: {
                        value: _vm.from.gross_weight_max,
                        callback: function ($$v) {
                          _vm.$set(_vm.from, "gross_weight_max", $$v)
                        },
                        expression: "from.gross_weight_max",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "title-query-input" }, [
                _c("div", { staticClass: "font" }, [_vm._v("售后率：")]),
                _c(
                  "div",
                  {
                    staticClass: "input-item",
                    staticStyle: { "margin-right": "10px" },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { clearable: false, placeholder: "选择售后率" },
                        model: {
                          value: _vm.from.aftersale_rate_day,
                          callback: function ($$v) {
                            _vm.$set(_vm.from, "aftersale_rate_day", $$v)
                          },
                          expression: "from.aftersale_rate_day",
                        },
                      },
                      [
                        _c("el-option", {
                          key: "3",
                          attrs: { label: "3天售后率", value: "3" },
                        }),
                        _c("el-option", {
                          key: "7",
                          attrs: { label: "7天售后率", value: "7" },
                        }),
                        _c("el-option", {
                          key: "30",
                          attrs: { label: "30天售后率", value: "30" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "input-item-tow" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "售后率始", clearable: "" },
                      on: {
                        blur: function ($event) {
                          return _vm.handleInputMoney(
                            _vm.from.aftersale_rate_min,
                            "aftersale_rate_min"
                          )
                        },
                      },
                      model: {
                        value: _vm.from.aftersale_rate_min,
                        callback: function ($$v) {
                          _vm.$set(_vm.from, "aftersale_rate_min", $$v)
                        },
                        expression: "from.aftersale_rate_min",
                      },
                    }),
                    _c("span", { staticClass: "span" }, [_vm._v("至")]),
                    _c("el-input", {
                      attrs: { placeholder: "售后率止", clearable: "" },
                      on: {
                        blur: function ($event) {
                          return _vm.handleInputMoney(
                            _vm.from.aftersale_rate_max,
                            "aftersale_rate_max"
                          )
                        },
                      },
                      model: {
                        value: _vm.from.aftersale_rate_max,
                        callback: function ($$v) {
                          _vm.$set(_vm.from, "aftersale_rate_max", $$v)
                        },
                        expression: "from.aftersale_rate_max",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "title-query-input" }, [
                _c("div", { staticClass: "font" }, [_vm._v("发起率：")]),
                _c(
                  "div",
                  {
                    staticClass: "input-item",
                    staticStyle: { "margin-right": "10px" },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { clearable: false, placeholder: "选择发起率" },
                        model: {
                          value: _vm.from.aftersale_apply_rate_day,
                          callback: function ($$v) {
                            _vm.$set(_vm.from, "aftersale_apply_rate_day", $$v)
                          },
                          expression: "from.aftersale_apply_rate_day",
                        },
                      },
                      [
                        _c("el-option", {
                          key: "3",
                          attrs: { label: "3天发起率", value: "3" },
                        }),
                        _c("el-option", {
                          key: "7",
                          attrs: { label: "7天发起率", value: "7" },
                        }),
                        _c("el-option", {
                          key: "30",
                          attrs: { label: "30天发起率", value: "30" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "input-item-tow" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "发起率始", clearable: "" },
                      on: {
                        blur: function ($event) {
                          return _vm.handleInputMoney(
                            _vm.from.aftersale_apply_rate_min,
                            "aftersale_apply_rate_min"
                          )
                        },
                      },
                      model: {
                        value: _vm.from.aftersale_apply_rate_min,
                        callback: function ($$v) {
                          _vm.$set(_vm.from, "aftersale_apply_rate_min", $$v)
                        },
                        expression: "from.aftersale_apply_rate_min",
                      },
                    }),
                    _c("span", { staticClass: "span" }, [_vm._v("至")]),
                    _c("el-input", {
                      attrs: { placeholder: "发起率止", clearable: "" },
                      on: {
                        blur: function ($event) {
                          return _vm.handleInputMoney(
                            _vm.from.aftersale_apply_rate_max,
                            "aftersale_apply_rate_max"
                          )
                        },
                      },
                      model: {
                        value: _vm.from.aftersale_apply_rate_max,
                        callback: function ($$v) {
                          _vm.$set(_vm.from, "aftersale_apply_rate_max", $$v)
                        },
                        expression: "from.aftersale_apply_rate_max",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "title-query-input" }, [
                _c("div", { staticClass: "font" }, [_vm._v("产地发货时间：")]),
                _c(
                  "div",
                  { staticClass: "input-item-tow" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "datetimerange",
                        clearable: "",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                      },
                      on: {
                        change: (val) => _vm.timechange(val, "delivery_time"),
                      },
                      model: {
                        value: _vm.delivery_time,
                        callback: function ($$v) {
                          _vm.delivery_time = $$v
                        },
                        expression: "delivery_time",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "title-query-input" }, [
                _c("div", { staticClass: "font" }, [
                  _vm._v("新货到市场时间："),
                ]),
                _c(
                  "div",
                  { staticClass: "input-item-tow" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "datetimerange",
                        clearable: "",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                      },
                      on: {
                        change: (val) => _vm.timechange(val, "arrival_time"),
                      },
                      model: {
                        value: _vm.arrival_time,
                        callback: function ($$v) {
                          _vm.arrival_time = $$v
                        },
                        expression: "arrival_time",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "title-query-input" }, [
                _c("div", { staticClass: "font" }, [
                  _vm._v("剩余货架期/保质期："),
                ]),
                _c(
                  "div",
                  { staticClass: "input-item-tow" },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: "剩余货架期/保质期",
                        clearable: "",
                      },
                      on: {
                        blur: function ($event) {
                          return _vm.handleInputMoney(
                            _vm.from.shelf_life_min,
                            "shelf_life_min"
                          )
                        },
                      },
                      model: {
                        value: _vm.from.shelf_life_min,
                        callback: function ($$v) {
                          _vm.$set(_vm.from, "shelf_life_min", $$v)
                        },
                        expression: "from.shelf_life_min",
                      },
                    }),
                    _c("span", { staticClass: "span" }, [_vm._v("至")]),
                    _c("el-input", {
                      attrs: {
                        placeholder: "剩余货架期/保质期",
                        clearable: "",
                      },
                      on: {
                        blur: function ($event) {
                          return _vm.handleInputMoney(
                            _vm.from.shelf_life_max,
                            "shelf_life_max"
                          )
                        },
                      },
                      model: {
                        value: _vm.from.shelf_life_max,
                        callback: function ($$v) {
                          _vm.$set(_vm.from, "shelf_life_max", $$v)
                        },
                        expression: "from.shelf_life_max",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "title-query-input" }, [
                _c("div", { staticClass: "font" }, [_vm._v("库存：")]),
                _c(
                  "div",
                  { staticClass: "input-item-tow" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "库存始", clearable: "" },
                      on: {
                        blur: function ($event) {
                          return _vm.handleInputMoney(
                            _vm.from.stock_min,
                            "stock_min"
                          )
                        },
                      },
                      model: {
                        value: _vm.from.stock_min,
                        callback: function ($$v) {
                          _vm.$set(_vm.from, "stock_min", $$v)
                        },
                        expression: "from.stock_min",
                      },
                    }),
                    _c("span", { staticClass: "span" }, [_vm._v("至")]),
                    _c("el-input", {
                      attrs: { placeholder: "库存止", clearable: "" },
                      on: {
                        blur: function ($event) {
                          return _vm.handleInputMoney(
                            _vm.from.stock_max,
                            "stock_max"
                          )
                        },
                      },
                      model: {
                        value: _vm.from.stock_max,
                        callback: function ($$v) {
                          _vm.$set(_vm.from, "stock_max", $$v)
                        },
                        expression: "from.stock_max",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "title-query-input" }, [
                _c("div", { staticClass: "font" }, [_vm._v("提审时间：")]),
                _c(
                  "div",
                  { staticClass: "input-item-tow" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "datetimerange",
                        clearable: "",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                      },
                      on: {
                        change: (val) => _vm.timechange(val, "audit_time"),
                      },
                      model: {
                        value: _vm.audit_time,
                        callback: function ($$v) {
                          _vm.audit_time = $$v
                        },
                        expression: "audit_time",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "title-query-input" }, [
                _c("div", { staticClass: "font" }, [_vm._v("产地：")]),
                _c(
                  "div",
                  { staticClass: "input-item" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入产地", clearable: "" },
                      model: {
                        value: _vm.from.origin_name,
                        callback: function ($$v) {
                          _vm.$set(_vm.from, "origin_name", $$v)
                        },
                        expression: "from.origin_name",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "title-query-btn" }, [
          _c(
            "div",
            { staticClass: "title-query-btn-left" },
            _vm._l(_vm.taglist, function (item) {
              return _c(
                "el-button",
                {
                  key: item.id,
                  attrs: {
                    type: _vm.from.audit_status == item.id ? "primary" : "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.tagquery(item)
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(item.name) + "(" + _vm._s(_vm.tagnum[item.num]) + ")"
                  ),
                ]
              )
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "title-query-btn-right" },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-s-fold", plain: "" },
                  on: {
                    click: function ($event) {
                      _vm.hideshow = !_vm.hideshow
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.hideshow ? "隐藏" : "展开"))]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.querylist } },
                [_vm._v("查询")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh-right" },
                  on: { click: _vm.Reset },
                },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "title-query-item" },
          _vm._l(_vm.tag_list, function (item, index) {
            return _c(
              "el-tag",
              {
                key: index,
                attrs: { closable: "" },
                on: {
                  close: function ($event) {
                    return _vm.close(item, index)
                  },
                },
              },
              [_vm._v(_vm._s(item.name))]
            )
          }),
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "auto-table-flex" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              key: _vm.tableKey,
              ref: "table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                height: "100%",
                border: true,
                "header-cell-style": {
                  color: "#333333",
                  background: "#EFF6FF",
                },
                "tooltip-effect": "dark",
              },
              on: { "header-dragend": _vm.handleHeaderDragEnd },
            },
            [
              _vm._l(_vm.columns, function (column) {
                return _c("el-table-column", {
                  key: column.prop,
                  attrs: {
                    prop: column.prop,
                    label: column.label,
                    align: "center",
                    fixed: column.fixed,
                    "min-width": column.minWidth,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            column.prop == "price"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "title-item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.sortChange(1)
                                      },
                                    },
                                  },
                                  [
                                    _c("div", [_vm._v("开市价")]),
                                    _c(
                                      "div",
                                      { staticClass: "title-item-icon" },
                                      [
                                        _c("i", {
                                          class:
                                            _vm.from.sort_key == "price" &&
                                            _vm.from.sort == "asc"
                                              ? "el-icon-caret-top active"
                                              : "el-icon-caret-top ",
                                        }),
                                        _c("i", {
                                          class:
                                            _vm.from.sort_key == "price" &&
                                            _vm.from.sort == "desc"
                                              ? "el-icon-caret-bottom active"
                                              : "el-icon-caret-bottom",
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              : column.prop == "weight"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "title-item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.sortChange(2)
                                      },
                                    },
                                  },
                                  [
                                    _c("div", [_vm._v("净重")]),
                                    _c(
                                      "div",
                                      { staticClass: "title-item-icon" },
                                      [
                                        _c("i", {
                                          class:
                                            _vm.from.sort_key == "weight" &&
                                            _vm.from.sort == "asc"
                                              ? "el-icon-caret-top active"
                                              : "el-icon-caret-top ",
                                        }),
                                        _c("i", {
                                          class:
                                            _vm.from.sort_key == "weight" &&
                                            _vm.from.sort == "desc"
                                              ? "el-icon-caret-bottom active"
                                              : "el-icon-caret-bottom",
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              : column.prop == "gross_weight"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "title-item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.sortChange(3)
                                      },
                                    },
                                  },
                                  [
                                    _c("div", [_vm._v("毛重")]),
                                    _c(
                                      "div",
                                      { staticClass: "title-item-icon" },
                                      [
                                        _c("i", {
                                          class:
                                            _vm.from.sort_key ==
                                              "gross_weight" &&
                                            _vm.from.sort == "asc"
                                              ? "el-icon-caret-top active"
                                              : "el-icon-caret-top ",
                                        }),
                                        _c("i", {
                                          class:
                                            _vm.from.sort_key ==
                                              "gross_weight" &&
                                            _vm.from.sort == "desc"
                                              ? "el-icon-caret-bottom active"
                                              : "el-icon-caret-bottom",
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              : _c("div", [_vm._v(_vm._s(column.label))]),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            column.prop == "title"
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onHandelDetails(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(scope.row.title))]
                                    ),
                                  ],
                                  1
                                )
                              : column.prop == "service_charges"
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$empty.empty(
                                          scope.row.service_charges
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : column.prop == "categories_name"
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.categories_name) +
                                      _vm._s(
                                        scope.row.category_tag_name
                                          ? `-${scope.row.category_tag_name}`
                                          : ""
                                      ) +
                                      " "
                                  ),
                                ])
                              : column.prop == "kv"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "imgs",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onHandelDetails(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "img-box",
                                      attrs: { src: scope.row.kv, alt: "" },
                                    }),
                                    scope.row.kv_last_time
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.timename(
                                                scope.row.kv_last_time
                                              )
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              : column.prop == "audit_status"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      { attrs: { type: "text" } },
                                      [
                                        _vm._v(
                                          _vm._s(scope.row.audit_status_txt)
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row[`${column.prop}`]) +
                                      " "
                                  ),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: "center",
                  "min-width": "200",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.audit_status == 101 ||
                        scope.row.audit_status == 104
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.adopt(scope.row)
                                  },
                                },
                              },
                              [_vm._v("通过")]
                            )
                          : _vm._e(),
                        scope.row.audit_status == 101 ||
                        scope.row.audit_status == 104
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "danger", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.reject(scope.row)
                                  },
                                },
                              },
                              [_vm._v("驳回")]
                            )
                          : _vm._e(),
                        scope.row.product_audit_refuse_reason == 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "success", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removefeom(scope.row)
                                  },
                                },
                              },
                              [_vm._v("下架原因")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fy" },
        [
          _c("el-pagination", {
            attrs: {
              type: "primary",
              background: "",
              "current-page": _vm.currentPage,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.from.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm.centerDialogVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "dialog",
              attrs: {
                title: "是否驳回",
                visible: _vm.centerDialogVisible,
                width: "500px",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.centerDialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "contents" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      staticClass: "demo-ruleForm",
                      attrs: {
                        model: _vm.ruleForm,
                        rules: _vm.rules,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "驳回原因", prop: "reason" } },
                        [
                          _c(
                            "div",
                            { staticStyle: { width: "302px" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  placeholder: "请输入内容",
                                  maxlength: "200",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.ruleForm.reason,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "reason", $$v)
                                  },
                                  expression: "ruleForm.reason",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "图片上传" } },
                        [
                          _c("b-file-upload", {
                            key: "reject_img",
                            attrs: {
                              accepts: ["jpg", "jpeg", "png"],
                              limit: 4,
                            },
                            model: {
                              value: _vm.detailPicList,
                              callback: function ($$v) {
                                _vm.detailPicList = $$v
                              },
                              expression: "detailPicList",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.centerDialogVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.ruleFormqr },
                    },
                    [_vm._v("确认")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c("GoodsDetails", { ref: "GoodsDetailsref" }),
      _c("tabledetails", { ref: "tabledetailsref" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }